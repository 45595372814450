<template>
  <v-dialog v-model="toggle" max-width="500" persistent>
    <v-card>
      <v-container>
        <OrganizzeModalHeader
          :loading="loading"
          :isDelete="true"
          :title="contact.name"
          @onClose="closeModal()"
        />

        <v-row style="justify-content: center;">
          <v-col md="8" style="text-align: center;">
            <h4>{{'Tem certeza que deseja excluir o contato ' + contact.name + '?'}}</h4>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="secondary"
              style="width: 100%;"
              :loading="loading"
              @click="excluirContato()">
              Confirmar
            </v-btn>
          </v-col>

          <v-col md="8" style="text-align: center;">
            <v-btn
              class="br-btn"
              color="error"
              style="width: 100%;"
              :loading="loading"
              @click="closeModal()">
              Cancelar
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import Events from '@/core/service/events'
import { mapActions } from 'vuex'
import { errorSnackbar, successSnackbar } from '@/core/service/utils'

const namespaceStore = 'organizzeContatos'

export default {
  name: 'OrganizzeContatosModalExcluir',

  components: {
    OrganizzeModalHeader: () => import('@/views/components/organizze/modal/header')
  },

  data: (() => ({
    toggle: false,
    contact: {},
    loading: false
  })),

  mounted () {
    const self = this

    Events.$on('modalExcluir::open', data => {
      self.toggle = true
      self.contact =  data.contact
    })
  },

  methods: {
    ...mapActions(namespaceStore, ['deleteContact']),

    closeModal () {
      const self = this
      self.toggle = false
    },

    excluirContato () {
      const self = this

      self.loading = true
      self.deleteContact({ id: self.contact.id }).then(() => {
        successSnackbar('Contato excluído com sucesso.')
        Events.$emit('index::getContacts')
        self.closeModal()
      }).catch(e => {
        console.error(e)
        errorSnackbar(e)
      }).finally(() => {
        self.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';

  .v-card { border-radius: 16px !important; }

  .v-btn { text-transform: uppercase; }
</style>